import React, { useState } from "react"
import Layout from "../components/layout"
import styled from 'styled-components'

// Components
import ReactCountryFlag from "react-country-flag"
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail'
import { Map } from '../components/map.js'
import Form from 'react-bootstrap/Form'
import ReCAPTCHA from 'react-google-recaptcha'

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    padding: 0vh 0 20vh 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`

const Component = styled.div`
    width: 90vw;
    margin: 0 5vw 0 5vw; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${ props => props.center ? 'center' : 'flex-start' };
    padding-bottom: 10vh;

    @media (min-width: 992px) {
        width: ${ props => props.full ? '100vw' : '50vw' };
    }

`

const Contact = props => {

    return ( 
        <Layout
            title = { 'Contact Us' }
            description = { ' Questions?  Quick responses' }
            index
        >
            <div className="site-About">
                <Wrapper>
                    <Component full>
                        <h2>Map</h2>
                        <div
                            style = {{
                                margin: '3vw 0',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <h3
                                style = {{
                                    textDecoration: 'underline'
                                }}
                            >
                                Canada Office &amp; Showroom
                            </h3>
                            2460 Viking Way, Richmond<br/>
                            British Columbia, Canada
                        </div>
                        <Map 
                            style = {{
                                width : '90%',
                                marginBottom: '5vw',
                            }}
                        />
                        <div
                            style = {{
                                margin: '3vw 0',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <h3
                                style = {{
                                    textDecoration: 'underline'
                                }}
                            >
                                USA Representative Office
                            </h3>
                            230 - 5th Ave # 308, New York,<br/>
                            NY 10001, United States
                        </div>
                        <Map 
                            country = { 'us' }
                            style = {{
                                width : '90%'
                            }}
                        />
                    </Component>
                    <Component full>
                        <h3>Contact us</h3>
                        <ContactForm/>
                    </Component>
                    <Component full>
                        <h5 className = "major">Phone</h5>
                        <p><PhoneIcon />&nbsp;&nbsp;(604) 275-5428</p>
                        <br/>
                        <h5 className = "major">Toll Free</h5>
                        <p><PhoneIcon />&nbsp;&nbsp;(800) 383-4634<br/><br/></p>
                        <h5>Fax</h5>
                        <p><PhoneIcon />&nbsp;&nbsp;604-275-5968<br/><br/></p>

                        <h5 className = "major">Sales</h5>
                        <a href = 'mailto:sales@siunsons.com'><MailIcon />&nbsp;&nbsp;sales@siunsons.com</a>
                        <br/>
                        <br/>
                        <h5 className = "major">Customer Service</h5>
                        <a href = 'mailto:shop@siunsons.com'><MailIcon />&nbsp;&nbsp;shop@siunsons.com</a>
                        <br/>
                        <br/>
                        <h5 className = "major">Technical Support</h5>
                        <a href = 'mailto:shop@siunsons.com'><MailIcon />&nbsp;&nbsp;shop@siunsons.com</a>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </Component>
                    <Component full>
                        <h3 className = "major"  style = {{ display: 'flex', alignItems: 'center'}} ><ReactCountryFlag countryCode="CA" svg  style = {{ margin: 0 }} />&nbsp;&nbsp;Canada</h3>
                        <strong>Kelowna</strong>
                        <div>Al Fuhrman</div>
                        <div>250-762-3984</div>
                        <br/>
                        <br/>
                        <br/><strong>Quebec</strong>
                        <div>Kizelnik Moshe (Kizzy)</div>
                        <div>514-201-4732</div>
                        <br/>
                        <a href = 'mailto:moshekizzy@gmail.com'  ><MailIcon />&nbsp;&nbsp;moshekizzy@gmail.com</a>
                        <br/>
                        <br/>
                        <strong>Ontario</strong>
                        <div>Ali Abid</div>
                        <div>416-738-5381</div>
                        <br/>
                        <a href = 'mailto:epicmarketing@hotmail.com'  ><MailIcon />&nbsp;&nbsp;epicmarketing@hotmail.com</a>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h3 className = "major"  style = {{ display: 'flex', alignItems: 'center'}} ><ReactCountryFlag countryCode="US" svg  style = {{ margin: 0 }} />&nbsp;&nbsp;United States</h3>
                        <strong>Puerto Rico</strong>
                        <div>Jose Negron</div>
                        <div>787-410-5838</div>
                        <br/>
                        <a href = 'mailto:dylandistributorcorp@gmail.com'  ><MailIcon />&nbsp;&nbsp;dylandistributorcorp@gmail.com</a>
                        <br/>
                        <br/>
                        <strong>Washington</strong>
                        <div>Norman North</div>
                        <div>206-669-1676</div>
                        <br/>
                        <a href = 'mailto:normnorth@hotmail.com'  ><MailIcon />&nbsp;&nbsp;normnorth@hotmail.com</a>
                        <br/>
                        <br/>
                        <strong>New York, New Jersey</strong>
                        <div>Nigel FeBland</div>
                        <div>212-675-3790</div>
                        <br/>
                        <a href = 'mailto:TheFeBlandGroup@gmail.com'  ><MailIcon />&nbsp;&nbsp;TheFeBlandGroup@gmail.com</a>
                        <br/>
                        <br/>
                    </Component>
                </Wrapper>
            </div>
        </Layout>
    )
}

export default Contact

const formUrl = 'https://api.formcake.com/api/form/51997444-5fd7-47e7-9b6f-779e67b59df7/submission'

const ContactForm = props => {

    const [ email, setEmail ] = useState('')
    const [ name, setName ] = useState('')
    const [ name2, setName2 ] = useState('')
    const [ company, setCompany ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ message, setMessage ] = useState('')
    const [ url, setUrl ] = useState(formUrl)

    const handleSetName2 = e => {
        const isValid = e.target.value === ''

        const newUrl = isValid ? formUrl : ''

        setUrl(newUrl)
        setName2(e.target.value)
    }


    return(
        <form 
            method = 'post' 
            action = {url} 
        >
            <Form.Group controlId = "formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control 
                    type = "text" 
                    maxLength = { 100 } 
                    value = { name } 
                    onChange = { e => setName( e.target.value ) } 
                    name = { 'name' }
                />
            </Form.Group>

            <Form.Group controlId = 'formBasicEmail' style = {{ display: 'none' }}>
                <Form.Label>Name</Form.Label>
                <Form.Control 
                    type = 'text' 
                    maxLength = { 100 } 
                    value = { name2 } 
                    onChange = { handleSetName2 } 
                    name = 'Name'
                />
            </Form.Group>

            <Form.Group controlId = "formBasicEmail">
                <Form.Label>Company name</Form.Label>
                <Form.Control 
                    type = "text" 
                    maxLength = { 100 } 
                    value = { company } 
                    onChange = { e => setCompany( e.target.value ) } 
                    name = { 'company' }
                />
            </Form.Group>

            <Form.Group controlId = "formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                    type = "email"  
                    maxLength = { 100 } 
                    value = { email }
                    onChange = { e => setEmail( e.target.value ) } 
                    name = { 'Email' }
                />
                                
                <Form.Text 
                    className="text-muted"
                >
                    Privacy Protection: your email address will not be shared with any third party.
                </Form.Text>
            </Form.Group>

            <Form.Group controlId = "formBasicEmail">
                <Form.Label>Phone</Form.Label>
                <Form.Control 
                    type = "text"  
                    maxLength = { 100 } 
                    value = { phone }
                    onChange = { e => setPhone( e.target.value ) } 
                    name = { 'phone' }
                />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Message or Comment</Form.Label>
                <Form.Control 
                    type = "text" 
                    as = { 'textarea' } 
                    style = {{ minHeight: '250px' }} 
                    maxLength = { 2000 } 
                    value = { message }
                    onChange = { e => setMessage( e.target.value ) } 
                    name = { 'message' }                
                />
                <Form.Text className="text-muted">
                    Your enquiry will be responded within 24 hours. Thank you!
                </Form.Text>
            </Form.Group>  

            <input 
                type = "submit" 
                value = "Submit" 
                style = {{ 
                    width: '100%', 
                    borderRadius: 0,
                    border: 0, 
                    backgroundColor: 'rgb(255, 87, 34)',
                    color: 'white',
                    lineHeight: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}            
            />                             
        </form>
    )
}
